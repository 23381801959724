.sider {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 28px 0;
  z-index: 10;
}
.sider :global .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.brand {
  z-index: 1;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  background: #002140;
  box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2);
}
.menuContainer {
  height: calc(100vh - 120px);
  overflow-x: hidden;
  flex: 1;
  padding: 24px 0;
}
.menuContainer::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.menuContainer:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.menuContainer :global .ant-menu-inline {
  border-right: none;
}
